import React, { useState, useEffect } from 'react';
import { Link, navigate } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import ReactPaginate from 'react-paginate';
import "./uworldexp.style.css";
import parse from "html-react-parser";

//import { TreeView, ITreeItem, TreeViewSelectionMode } from "@pnp/spfx-controls-react/lib/TreeView";

function toLetters(num) {
    "use strict";
    var mod = num % 26,
        pow = num / 26 | 0,
        out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? toLetters(pow) + out : out;
}

export const ExampleUworldQuestionWrapper_NoTable_NoImage = () => (
  <div id="question">
    <div id="questionText" className="questionText ng-scope">
      <p className="ng-scope">
        A 13-year-old girl is brought to the clinic by her mother for a yearly
        physical examination.&nbsp; The patient feels well but is worried that
        she has not yet started puberty.&nbsp; Temperature is 36.7 C (98 F),
        blood pressure is 152/91 mm Hg, pulse is 75/min, and respirations are
        18/min.&nbsp; Physical examination is significant for a lack of
        secondary sexual characteristics; a blind vagina is noted on pelvic
        examination.&nbsp; Laboratory studies reveal hypokalemia and low
        testosterone and estradiol levels.&nbsp; Cytogenetic analysis shows a
        46,XY karyotype.&nbsp; This patient most likely has deficiency of which
        of the following enzymes?
      </p>
    </div>
    <div id="answerContainer">
      <table
        id="answerChoicesTable"
        className="answercontainer ng-scope border"
      >
        <tbody>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-untouched ng-valid ng-scope ng-dirty disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={1}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">A.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight1">
                        <span style={{ textDecoration: "line-through" }}>
                          {" "}
                          <span className="ng-binding">
                            5 alpha-reductase
                          </span>{" "}
                        </span>
                      </span>{" "}
                      <span className="ng-binding ng-scope">(11%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={2}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">B.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight2">
                        {" "}
                        <span className="ng-binding">
                          11 beta-hydroxylase
                        </span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(11%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={3}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">C.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight3">
                        {" "}
                        <span className="ng-binding">
                          17 alpha-hydroxylase
                        </span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(66%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey checked"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-untouched ng-valid ng-scope ng-dirty ng-valid-parse disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={4}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">D.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight4">
                        {" "}
                        <span className="ng-binding">20,22-desmolase</span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(2%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={5}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">E.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight5">
                        <span style={{ textDecoration: "line-through" }}>
                          {" "}
                          <span className="ng-binding">
                            21-hydroxylase
                          </span>{" "}
                        </span>
                      </span>{" "}
                      <span className="ng-binding ng-scope">(8%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="clearfix">&nbsp;</div>
    </div>
    <div className="ng-scope" />
    <div id="prevQuestionBox" style={{ display: "none" }}>
      <div id="prevQuestionBoxHeader">
        <div className="pull-right">
          <div className="header-close">&nbsp;</div>
        </div>
      </div>
      <div id="prevQuestionBoxContent">
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
)

function ExperimentOptionSingleWrapper_NoTable_NoImage(props) {
    return(
       <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-untouched ng-valid ng-scope ng-dirty disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={1}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">{toLetters(props.OptionNonZeroIndex)}.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight1">
                        <span style={{ textDecoration: "line-through" }}>
                          {" "}
                          <span className="ng-binding">
                            {props.OptionText}
                          </span>{" "}
                        </span>
                      </span>{" "}
                      <span className="ng-binding ng-scope">{props.Percent?props.Percent:"(11%)"}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
    )
}
export const ExperimentExampleUworldQuestionWrapper_NoTable_NoImage = (props) => (
  <div id="question">
       {props.Options.map((option,index) => (
            <ExperimentOptionSingleWrapper_NoTable_NoImage IsStriked={false} OptionText={option}  OptionNonZeroIndex={index+1} OptionZeroIndex={index}   />
          ))}
    <div id="questionText" className="questionText ng-scope">
      <p className="ng-scope">
        A 13-year-old girl is brought to the clinic by her mother for a yearly
        physical examination.&nbsp; The patient feels well but is worried that
        she has not yet started puberty.&nbsp; Temperature is 36.7 C (98 F),
        blood pressure is 152/91 mm Hg, pulse is 75/min, and respirations are
        18/min.&nbsp; Physical examination is significant for a lack of
        secondary sexual characteristics; a blind vagina is noted on pelvic
        examination.&nbsp; Laboratory studies reveal hypokalemia and low
        testosterone and estradiol levels.&nbsp; Cytogenetic analysis shows a
        46,XY karyotype.&nbsp; This patient most likely has deficiency of which
        of the following enzymes?
      </p>
    </div>
    <div id="answerContainer">
      <table
        id="answerChoicesTable"
        className="answercontainer ng-scope border"
      >
        <tbody>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-untouched ng-valid ng-scope ng-dirty disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={1}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">A.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight1">
                        <span style={{ textDecoration: "line-through" }}>
                          {" "}
                          <span className="ng-binding">
                            5 alpha-reductase
                          </span>{" "}
                        </span>
                      </span>{" "}
                      <span className="ng-binding ng-scope">(11%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={2}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">B.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight2">
                        {" "}
                        <span className="ng-binding">
                          11 beta-hydroxylase
                        </span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(11%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={3}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">C.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight3">
                        {" "}
                        <span className="ng-binding">
                          17 alpha-hydroxylase
                        </span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(66%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey checked"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-untouched ng-valid ng-scope ng-dirty ng-valid-parse disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={4}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">D.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight4">
                        {" "}
                        <span className="ng-binding">20,22-desmolase</span>{" "}
                      </span>{" "}
                      <span className="ng-binding ng-scope">(2%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="answerChoiceBackground ng-scope">
            <td>
              <table style={{ margin: "3px 0px" }}>
                <tbody>
                  <tr>
                    <td style={{ minWidth: 80, maxWidth: 200 }}>
                      <div
                        style={{ float: "left", paddingTop: 3, minWidth: 25 }}
                      >
                        &nbsp;
                      </div>
                      <div style={{ float: "left" }}>
                        <label className="testInterfaceDisablecolor">
                          <label className="testInterfaceDisablecolor"> </label>
                        </label>
                        <div
                          className="iradio_square-grey"
                          style={{ position: "relative" }}
                        >
                          <input
                            className="ng-pristine ng-untouched ng-valid ng-scope disablecolor"
                            style={{ position: "absolute", opacity: 0 }}
                            disabled="disabled"
                            name="answerChoices"
                            type="radio"
                            defaultValue={5}
                          />
                          <ins
                            className="iCheck-helper"
                            style={{
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              display: "block",
                              width: "100%",
                              height: "100%",
                              margin: 0,
                              padding: 0,
                              background: "#ffffff",
                              border: 0,
                              opacity: 0
                            }}
                          />
                        </div>
                        <label className="testInterfaceDisablecolor"> </label>{" "}
                        <label className="testInterfaceDisablecolor"> </label>
                      </div>
                      <div
                        style={{
                          float: "left",
                          minWidth: 23,
                          textAlign: "left"
                        }}
                      >
                        &nbsp;<span className="ng-binding ng-scope">E.</span>{" "}
                      </div>
                    </td>
                    <td
                      className="answerChoiceContent"
                      style={{ display: "block" }}
                    >
                      <span id="answerhighlight5">
                        <span style={{ textDecoration: "line-through" }}>
                          {" "}
                          <span className="ng-binding">
                            21-hydroxylase
                          </span>{" "}
                        </span>
                      </span>{" "}
                      <span className="ng-binding ng-scope">(8%)</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="clearfix">&nbsp;</div>
    </div>
    <div className="ng-scope" />
    <div id="prevQuestionBox" style={{ display: "none" }}>
      <div id="prevQuestionBoxHeader">
        <div className="pull-right">
          <div className="header-close">&nbsp;</div>
        </div>
      </div>
      <div id="prevQuestionBoxContent">
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
)
ExperimentExampleUworldQuestionWrapper_NoTable_NoImage.defaultProps = {
    //CURRENT_QUESTION_JSON_DATA
    Question: "A 45-year-old patient is brought to the emergency room in adelirious state with weakness, blurred vision, dehydration, and hypothermia. He was diagnosed with type 1 diabetes mellitus 30 years back. On examination, he has a rapid pulse and her breathing is deep and labored, with a sickly sweet odor. His blood glucose is 256mg/dL and his urinary dipstick shows +2 ketonuria. What is the most important derangement to be seen on the arterial blood gases (ABG) of this patient?",
    correctAnswer: "Decreased HCO3-",
    Options: [
        "Increased Na+",
        "Decreased HCO3-",
        "Decreased pCO2",
        "Decreased K+",
        "Increased serum pH"
    ],
    Tags: "ELECTROLYTES AND ACID-BASE BALANCE MEDICINE-2",
    Televancy: "SUGGESTED QUESTION",
    totalQuestions: 27,
    currentQuestion: 2
};

function ExampleUworld(){
    return (
        <div>
            <Seo title="MedangleUworld" />
            <div>
              
                < ExperimentExampleUworldQuestionWrapper_NoTable_NoImage
                   
                    
                    />
            </div>
        </div>
    )
}

export default ExampleUworld